<template>
  <div v-if="loaded">
    <div :key="$i18n.locale + $route.name">
      <topMenu :items="getItems" />
      <topTitle :items="getItems" />
    </div>
    <updateData />
    <div v-if="$route.name.includes('shares')">
      <flixNavbarShares />
      <slot v-if="$slots.content" name="content"></slot>
    </div>
    <div v-else-if="$store.getters.user && $store.getters.user.md5_id">
      <pleaseConfirm v-if="$store.getters.user.status == 'not_validated'" />
      <div v-if="$store.getters.user.status == 'validated'">
        <loggedInBar />
        <div
          v-if="$store.getters.organisationName.name"
          class="flix-container-fluid flix-html-html flix-text-center flix-small flix-text-info"
          style="margin-bottom: 20px"
        >
          <flixIcon id="info-sign" /> Sie haben die zugewiesene Rolle
          <b>{{ $store.getters.organisationName.name }}</b> eingenommen.
          <router-link
            :to="{
              name: 'dashboardPermissions',
              params: { part: 'organisations', action: 'switch' }
            }"
            >Rolle wieder verlassen</router-link
          >.
        </div>
        <slot v-if="$slots.content" name="content"></slot>
        <membershipWarning style="margin-top: 30px" />
        <flixNavbarShares />
        <flixNavbarFooter />
      </div>
    </div>
  </div>
  <div v-else class="flix-text-center">
    <loader :callback="setLoaded" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loaded: false
    }
  },
  components: {
    loader() {
      return import('./layout/loader')
    },
    topMenu() {
      return import('./layout/menu')
    },
    topTitle() {
      return import('./layout/title')
    },
    membershipWarning() {
      return import('@/components/assistent/membershipWarning')
    },
    updateData() {
      return import('@/components/dashboard/updateData')
    },
    pleaseConfirm() {
      return import('@/components/dashboard/parts/pleaseConfirm')
    },
    flixNavbarShares() {
      return import('@/components/dashboard/navbarShares')
    },
    flixNavbarFooter() {
      return import('@/components/dashboard/navbarFooter')
    },
    loggedInBar() {
      return import('@/components/dashboard/loggedIn')
    }
  },
  methods: {
    setLoaded() {
      this.loaded = true
    },
    getID() {
      if (
        !this.$store.state.assistents.assistentActive ||
        typeof this.$store.state.assistents.assistentActive !== 'object'
      ) {
        return 'all'
      } else {
        return this.$store.state.assistents.assistentActive.ID
      }
    }
  },
  mounted() {},
  computed: {
    getItems() {
      return {
        appointments: {
          name: this.$tc('message.appointments', 2).toUpperCase(),
          icon: 'parents',
          items: [
            {
              permission: function () {
                return true
              },
              name: this.$tc('message.appointmentOverview', 1),
              link: { name: 'dashboard' },
              icon: 'tree-structure'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$checkPermission('customer', 2)
                ) {
                  return true
                }
              }.bind(this),
              name: this.$t('message.add', {
                name: this.$tc('message.appointments', 1)
              }),
              link: {
                name: 'dashboardAddCustomer',
                params: { id: this.getID() }
              },
              icon: 'important-day'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$store.getters.bookingsCount &&
                  this.$checkPermission('csvDownload', 1)
                ) {
                  return true
                }
              }.bind(this),
              name: this.$t('message.csvDownload'),
              link: {
                name: 'dashboardDownloadCenter',
                params: { id: this.getID() }
              },
              icon: 'download'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$checkPermission('customer', 2)
                ) {
                  return true
                }
              }.bind(this),
              name: this.$tc('message.customer', 2),
              link: { name: 'dashboardCRMall' },
              icon: 'group'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$checkPermission('customer', 2)
                ) {
                  return true
                }
              }.bind(this),
              name:
                this.$tc('message.appointmentOverview', 2) +
                ' ' +
                this.$tc('message.eventTitle') +
                '',
              link: { name: 'dashboardEventsBooking' },
              icon: 'charts'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$checkPermission('customer', 2)
                ) {
                  return true
                }
              }.bind(this),
              name:
                this.$tc('message.customer', 2) +
                ': ' +
                this.$t('message.checkIn') +
                ' / ' +
                this.$t('message.checkOut'),
              link: { name: 'dashboardCheckIn', params: { id: this.getID() } },
              icon: 'stopwatch'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$store.getters.bookingsCount &&
                  this.$checkPermission('csvDownload', 1)
                ) {
                  return true
                }
              }.bind(this),
              name: this.$tc('message.webinar', 2),
              link: { name: 'dashboardWebinar', params: { id: this.getID() } },
              icon: 'webcam'
            }
          ]
        },
        calendar: {
          name: this.$tc('message.calendar', 2).toUpperCase(),
          icon: 'calendar',
          items: [
            {
              permission: function () {
                if (this.$checkPermission('calendar', 3)) {
                  return true
                }
              }.bind(this),
              name: this.$tc('message.createNew', 1, {
                name: this.$tc('message.calendar', 1)
              }),
              link: { name: 'dashboardAssistentEdit', params: { id: 'new' } },
              icon: 'plus'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$checkPermission('calendar', 2)
                ) {
                  return true
                }
              }.bind(this),
              name: this.$tc('message.edit', 1, {
                name: this.$tc('message.calendar', 1)
              }),
              link: {
                name: 'dashboardAssistent',
                params: { id: this.getID() }
              },
              icon: 'wrench'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$checkPermission('page', 1)
                ) {
                  return true
                }
              }.bind(this),
              name: this.$t('message.embedCode'),
              link: { name: 'dashboardPublish', params: { id: this.getID() } },
              icon: 'embed'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$checkPermission('page', 2)
                ) {
                  return true
                }
              }.bind(this),
              name: this.$tc('message.pageBuilder', 1),
              link: {
                name: 'dashboardPageBuilder',
                params: { id: this.getID(), open: false }
              },
              icon: 'globe'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$checkPermission('calendar', 2)
                ) {
                  return true
                }
              }.bind(this),
              name: this.$t('message.connect', {
                name: this.$tc('message.calendar', 2)
              }),
              link: { name: 'dashboardRessources' },
              icon: 'git-compare'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$checkPermission('page', 1)
                ) {
                  return true
                }
              }.bind(this),
              name: this.$t('message.qrCode'),
              link: { name: 'dashboardQrCode', params: { id: this.getID() } },
              icon: 'qrcode'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$checkPermission('calendar', 2)
                ) {
                  return true
                }
              }.bind(this),
              name: this.$t('message.share', {
                name: this.$tc('message.calendar', 2)
              }),
              link: {
                name: 'dashboardPermissions',
                params: { part: 'organisations', action: 'overview' }
              },
              icon: 'share-alt'
            }
          ]
        },
        settings: {
          name: this.$tc('message.settings', 2).toUpperCase(),
          icon: 'cogwheel',
          items: [
            {
              permission: function () {
                if (this.$checkPermission('holidays', 2)) {
                  return true
                }
              }.bind(this),
              name: this.$t('message.edit', {
                name:
                  this.$tc('message.holidays', 2) +
                  ' & ' +
                  this.$tc('message.vacations', 2)
              }),
              link: { name: 'dashboardHolidays' },
              icon: 'plane'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$checkPermission('reminder', 1)
                ) {
                  return true
                }
              }.bind(this),
              name: this.$t('message.edit', {
                name: this.$tc('message.appointmentNotification', 2)
              }),
              link: {
                name: 'dashboardDefaultMessages',
                params: { id: this.getID() }
              },
              icon: 'message-out'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$checkPermission('reminder', 1)
                ) {
                  return true
                }
              }.bind(this),
              name: this.$t('message.edit', {
                name: this.$tc('message.reminder', 2)
              }),
              link: { name: 'dashboardReminder', params: { id: this.getID() } },
              icon: 'pending-notifications'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$checkPermission('targetPage', 2)
                ) {
                  return true
                }
              }.bind(this),
              name: this.$t('message.edit', {
                name: this.$tc('message.targetPage', 2)
              }),
              link: {
                name: 'dashboardSpecificTargetPage',
                params: { edit: 0, id: this.getID() }
              },
              icon: 'flag'
            },
            {
              permission: function () {
                if (this.$checkPermission('service', 2)) {
                  return true
                }
              }.bind(this),
              name: this.$tc('message.service', 2),
              link: { name: 'dashboardServices' },
              icon: 'handshake'
            }
          ]
        },
        share: {
          name: this.$tc('message.share', 2).toUpperCase(),
          icon: 'share-alt',
          items: [
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$checkPermission('calendar', 2)
                ) {
                  return true
                }
              }.bind(this),
              name: this.$t('message.share', {
                name: this.$tc('message.calendar', 2)
              }),
              link: {
                name: 'dashboardPermissions',
                params: { part: 'organisations', action: 'overview' }
              },
              icon: 'share-alt'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$checkPermission('page', 1)
                ) {
                  return true
                }
              }.bind(this),
              name: this.$t('message.embedCode'),
              link: { name: 'dashboardPublish', params: { id: this.getID() } },
              icon: 'embed'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$checkPermission('page', 2)
                ) {
                  return true
                }
              }.bind(this),
              name: this.$tc('message.pageBuilder', 1),
              link: {
                name: 'dashboardPageBuilder',
                params: { id: this.getID(), open: false }
              },
              icon: 'globe'
            },
            {
              permission: function () {
                if (this.$checkPermission('sync', 2)) {
                  return true
                }
              }.bind(this),
              name:
                this.$tc('message.externalCalendar', 1) +
                ' ' +
                this.$t('message.sync'),
              link: {
                name: 'dashboardCalendarIntegration',
                params: { id: this.getID() }
              },
              icon: 'random'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$checkPermission('sync', 2)
                ) {
                  return true
                }
              }.bind(this),
              name: this.$t('message.icalCalendar'),
              link: {
                name: 'dashboardIcalServer',
                params: { id: this.getID() }
              },
              icon: 'iphone'
            },
            {
              permission: function () {
                if (this.getID() && this.$checkPermission('webhooks', 2)) {
                  return true
                }
              }.bind(this),
              name: this.$tc('message.webhooks', 2),
              link: { name: 'dashboardWebhook', params: { id: this.getID() } },
              icon: 'cloud-upload'
            },
            {
              permission: function () {
                if (!this.getID() && this.$checkPermission('webhooks', 2)) {
                  return true
                }
              }.bind(this),
              name: this.$tc('message.webhooks', 2),
              link: { name: 'dashboardWebhooks', params: { id: 'all' } },
              icon: 'cloud-upload'
            }
          ]
        },
        profile: {
          name: this.$tc('message.profile', 2).toUpperCase(),
          icon: 'user',
          items: [
            {
              permission: function () {
                if (this.$checkPermission('customer', 3)) {
                  return true
                }
              }.bind(this),
              name: this.$t('message.edit', {
                name: this.$t('message.profile')
              }),
              link: { name: 'dashboardProfile', params: { id: 'profile' } },
              icon: 'vcard'
            },
            {
              permission: function () {
                if (this.$checkPermission('customer', 3)) {
                  return true
                }
              }.bind(this),
              name: this.$tc('message.statistic', 2),
              link: { name: 'dashboardStatistic' },
              icon: 'stats'
            },
            {
              permission: function () {
                if (this.$checkPermission('message', 1)) {
                  return true
                }
              }.bind(this),
              name: this.$tc('message.message', 2),
              link: { name: 'dashboardMessageCenter' },
              icon: 'bell'
            },
            {
              permission: function () {
                if (this.$checkPermission('customer', 3)) {
                  return true
                }
              }.bind(this),
              name: this.$t('message.credit'),
              link: { name: 'dashboardCredits' },
              icon: 'coins'
            },
            {
              permission: function () {
                if (this.$checkPermission('customer', 3)) {
                  return true
                }
              }.bind(this),
              name: this.$t('message.dataProcessingContract'),
              link: { name: 'dashboardDataProcessingContract' },
              icon: 'paragraph-alt'
            },
            {
              permission: function () {
                if (this.$checkPermission('customer', 3)) {
                  return true
                }
              }.bind(this),
              name: this.$t('message.membership'),
              link: {
                name: 'dashboardMembership',
                params: { id: 'membership' }
              },
              icon: 'crown'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$checkPermission('profile', 3)
                ) {
                  return true
                }
              }.bind(this),
              name: this.$tc('message.organisation', 2),
              link: {
                name: 'dashboardPermissions',
                params: { part: 'organisations', action: 'overview' }
              },
              icon: 'building'
            },
            {
              permission: function () {
                if (
                  this.$store.getters.assistents.length &&
                  this.$checkPermission('profile', 3)
                ) {
                  return true
                }
              }.bind(this),
              name: 'Server-Logs',
              link: { name: 'dashboardLogs' },
              icon: 'server'
            },
            {
              permission: function () {
                if (this.$checkPermission('customer', 3)) {
                  return true
                }
              }.bind(this),
              name: this.$tc('message.backup', 2),
              link: { name: 'dashboardBackup' },
              icon: 'random'
            }
          ]
        }
      }
    }
  }
}
</script>
<style lang="sass" scoped></style>
